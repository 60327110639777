<template>
  <b-modal
    id="category-exercise-modal"
    ref="category-exercise-modal"
    @show="createWithCopy"
    :title="
      popupType == 'detail'
        ? 'Cập nhật hình thức vận động'
        : popupType == 'create'
        ? 'Tạo hình thức vận động'
        : 'Xem hình thức vận động'
    "
    @hidden="resetModal"
    @ok="submit"
    :hide-footer="popupType == 'view'"
  >
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <form ref="form">
          <b-row>
            <b-col>
              <basic-input
                :disabled="popupType == 'view'"
                label="Mã hình thức vận động"
                placeholder="Nhập mã hình thức vận động"
                v-model="form.code"
                name="code"
                :value.sync="form.code"
                :state="validateState(`code`)"
                :invalid-feedback="errors.first(`code`)"
                v-validate="'alpha_num'"
                data-vv-as="Mã hình thức vận động"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                :required="true"
                :disabled="popupType == 'view'"
                label="Môn vận động"
                placeholder="--- Chọn môn vận động  ---"
                name="categoryExercise"
                v-validate="'required'"
                :state="validateState('categoryExercise')"
                :invalidFeedback="errors.first('categoryExercise')"
                data-vv-as="Môn thức vận động"
                :value.sync="form.exerciseCategoryId"
                :options="categories"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                :required="true"
                :disabled="popupType == 'view'"
                label="Hình thức vận động"
                placeholder="Nhập tên hình thức vận động"
                v-validate="'required'"
                :state="validateState('exercise')"
                :invalidFeedback="errors.first('exercise')"
                v-model="form.name"
                data-vv-as="Hình thức vận động"
                name="exercise"
                :value.sync="form.name"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                :required="true"
                inputType="number"
                :disabled="popupType == 'view'"
                label="METs"
                placeholder="Nhập chỉ số METs"
                v-validate="'required|decimal'"
                :state="validateState('METs')"
                :invalidFeedback="errors.first('METs')"
                v-model="form.mets"
                data-vv-as="Chỉ số METs"
                name="METs"
                :value.sync="form.mets"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                :disabled="popupType == 'view'"
                label="Loại cường độ vận động"
                placeholder="--- Chọn môn vận động  ---"
                name="intensity"
                :options="intensity"
                :required="true"
                v-validate="'required'"
                :state="validateState('intensity')"
                :invalidFeedback="errors.first('intensity')"
                data-vv-as="Cường độ vận động"
                :value.sync="form.exerciseIntensityId"
                :solid="false"
                :allowEmpty="false"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-text-area
                :disabled="popupType == 'view'"
                label="Ghi chú:"
                placeholder="Nhập ghi chú"
                :value.sync="form.description"
                name="note"
                :state="validateState('note')"
                :invalidFeedback="errors.first('note')"
              ></basic-text-area>
            </b-col>
          </b-row>
        </form>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div
        class="w-100 d-flex align-items-center justify-content-end"
        v-if="popupType != 'detail'"
      >
        <b-button class="btn btn-plain ml-2" type="submit" @click="hideModal">
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          type="submit"
          @click="handleValidate(false)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          type="submit"
          @click="handleValidate(true)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu và đặt làm mặc định
        </b-button>
      </div>
      <div v-else class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-inactive ml-3"
          type="submit"
          @click="handleInactive"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button class="btn ml-3" type="submit" @click="handleValidate(true)">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu và đặt làm mặc định
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="submit"
          @click="handleValidate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'CategoryExerciseModal',
  components: {},
  props: {
    category: {
      type: Array,
      default: () => [],
    },
    popupType: {
      type: String,
      default: 'detail',
    },
    id: {
      type: String,
      default: null,
    },
    categorySelected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      filter: {},
      form: {
        default: false,
        name: null,
        code: null,
        description: null,
        exerciseIntensityId: null,
        exerciseCategoryId: null,
        mets: null,
      },
      validationState: {
        categoryExerciseName: null,
        name: null,
        rate: null,
        note: null,
      },
      error: {
        categoryExerciseName: null,
        name: null,
        rate: null,
        note: null,
      },
      intensity: [],
    };
  },
  watch: {
    id: {
      handler(newVal) {
        if (newVal) {
          this.loadDetail(newVal);
        }
      },
    },
  },
  computed: {
    categories() {
      return this.category;
    },
  },
  methods: {
    createWithCopy() {
      setTimeout(() => {
        if (this.popupType === 'create' && this.categorySelected) {
          this.form.exerciseCategoryId = this.categorySelected;
        }
      }, 500);
    },

    async loadDetail(id) {
      this.loading = true;
      try {
        let { data } = await this.$api.get(`Admin/Exercise/Exercise/${id}`);
        this.form = data;
        this.form.exerciseCategoryId = this.category.find(
          (e) => e.id === data.exerciseCategoryId,
        );
        this.form.exerciseIntensityId = this.intensity.find(
          (e) => e.id === data.intensityId,
        );
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async handleInactive() {
      // http://api.dev.diab.cptech.vn/App/Admin/Exercise/Exercise/Input
      let payload = { ...this.form };
      payload.exerciseCategoryId = this.form.exerciseCategoryId.id;
      payload.exerciseIntensityId = this.form.exerciseIntensityId.id;
      payload.status = this.form.status === 0 ? 1 : 0;
      try {
        await this.$api.put('Admin/Exercise/Exercise/Input', payload);
        this.$toastr.s({
          title: 'Thành công !',
          msg: `${
            this.form.status === 0
              ? 'Active thành công '
              : 'Inactive thành công'
          }`,
        });
        this.$bvModal.hide('category-exercise-modal');
        this.$emit('load');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    handleValidate(isDefault) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.submit(isDefault);
      });
    },

    async submit(isDefault) {
      if (typeof isDefault === 'boolean') {
        this.form.default = isDefault;
      }
      this.loading = true;
      try {
        if (this.popupType === 'detail') {
          this.form.exerciseCategoryId = this.form.exerciseCategoryId.id;
          this.form.exerciseIntensityId = this.form.exerciseIntensityId.id;
          await this.$api.put('Admin/Exercise/Exercise/Input', this.form);
        } else {
          if (typeof this.form.exerciseCategoryId == 'object') {
            this.form.exerciseCategoryId = this.form.exerciseCategoryId.id;
          }
          if (typeof this.form.exerciseIntensityId == 'object') {
            this.form.exerciseIntensityId = this.form.exerciseIntensityId.id;
          }
          await this.$api.post('Admin/Exercise/Exercise/Input', this.form);
        }
        this.$toastr.s({
          title: 'Thành công  !',
          msg: `${
            this.popupType === 'detail' ? 'Cập nhật' : 'Tạo'
          } môn vận động thành công`,
        });
        this.$bvModal.hide('category-exercise-modal');
        this.$emit('load');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    hideModal() {
      this.$bvModal.hide('category-exercise-modal');
    },
    resetModal() {
      this.form.default = false;
      this.form.name = null;
      this.form.code = null;
      this.form.description = null;
      this.form.exerciseIntensityId = null;
      this.form.exerciseCategoryId = null;
      this.form.mets = null;
      this.file = null;
      this.preview = null;
      this.$emit('update:categorySelected', null);
      this.$emit('update:id', null);
    },
    async getIntensity() {
      try {
        let { data } = await this.$api.get('Admin/Exercise/Intensity');
        this.intensity = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
  },
  created() {
    this.getIntensity();
  },
};
</script>

<style lang="scss">
#glucose-modal {
  .modal-dialog {
    width: 410px;
    height: 582px;
  }
}
</style>
